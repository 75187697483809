import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventHeader from '../components/eventheader';


function escapeRegExp(str) {
  return str.replace(/[-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

function clean($input){
  var $find = `href="https://stirtingale.uk/platocms/`;
  var $replace = `href="`;
  return $input.replace(new RegExp(escapeRegExp($find), 'g'), $replace);
}

function customseo_title($customseo_input,$page_title){
	if( $customseo_input != null ){
		return $customseo_input;
	} else {
		return $page_title;
	}
}

export default ({pageContext}) => (
	<Layout>
		<SEO 
		title={customseo_title(pageContext.acf.custom_title,pageContext.title)}
		description={pageContext.acf.custom_description}
		/>
	    <div id="event-single" class="header-container --simple --eventsingle">
	    <EventHeader/>
	    <div class="colour-mask"></div>
	    <div class="grid-container">
	      <div class="grid-x setheight">
	        <div class="cell small-12 medium-10 medium-offset-1">
	            <div class="header-container-simple ">
	            </div>
	            <div class="inner --simple">
	            <h2 dangerouslySetInnerHTML={{__html:pageContext.title}}/>
	            <ul>
	            <li>
	            <a href="/events"><svg xmlns="http://www.w3.org/2000/svg" fill="var(--white)" width="14" height="14" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg> Back to Events</a>
	            </li>
	            </ul>
	            </div>
	        </div>
	      </div>
	    </div>
	    </div>

	    <div class="grid-container">
	      <div class="grid-x">
			<article class='cell small-12 medium-10 medium-offset-1 large-8' dangerouslySetInnerHTML={{__html:clean(pageContext.content)}} />
	      </div>
	    </div>
	</Layout>
);
